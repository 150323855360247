<template>
  <div class="login-page">
    <div class="login-page__content">
      <IonIcon
        name="login-logo"
        class="login-page__content-logo"
      />

      <AtomHeading
        class="login-page__content-heading"
        html-tag="div"
        :text="$t('login.heading')"
        font-size="h1"
        color-schema="primary"
      />

      <form class="login-page__content-form" @submit.prevent="handleLogin">
        <AtomInputText
          :label="$t('login.email')"
          name="email"
          :validations="[{
            type: 'isNotEmpty',
            message: $t('global.errors.isRequired'),
          }]"
          @set-error="onSetErrors($event, 'email')"
          @set-input="({ value }) => email = value"
        />

        <AtomInputText
          :label="$t('login.password')"
          name="password"
          input-type="password"
          :validations="[{
            type: 'isNotEmpty',
            message: $t('global.errors.isRequired'),
          }]"
          @set-error="onSetErrors($event, 'password')"
          @set-input="({ value }) => password = value"
        />

        <AtomLink
          class="login-page__content-form&#45;&#45;link"
          :text="$t('login.forgotPassword')"
          link="/forgot-password"
        />

        <div class="login-page__content-form--buttons">
          <AtomButton
            type="submit"
            :text="buttonText"
            :is-disabled="hasErrors"
          />

          <UtilRouteLink link="/register">
            <AtomButton
              :text="$t('login.register')"
              is-inverted
            />
          </UtilRouteLink>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
definePageMeta({
    layout: 'fullscreen',
    middleware: [
        'auth-guest',
    ],
});

const i18n = useI18n();
const alertBox = useAlertBoxStore();
const {
    authLogin,
} = useAuthEndpoints();

const email = ref('');
const password = ref('');
const collectedErrors = ref({});
const hasErrors = computed(() => Object.keys(collectedErrors.value).length > 0);

/*
  Handle Errors
*/
const onSetErrors = (data, key) => {
    if (data?.length === 0) {
        delete collectedErrors.value[key];
        return;
    }

    collectedErrors.value[key] = data;
};

/*
  Handle Button Text
*/
const { getTranslation } = useHelpers();
const buttonText = computed(() => getTranslation('login.login'));

/*
  Handle Login
*/
const sendLoginRequest = async () => {
    try {
        await authLogin(email.value, password.value);
    } catch (e) {
        alertBox.setAlertBoxData('error', {
            error_text: i18n.t('global.errors.usernameOrPasswordIncorrect'),
        });
        alertBox.toggleAlertbox();
    }
};

const handleLogin = async () => {
    await sendLoginRequest();
};
</script>

<style lang="scss" scoped>
.login-page {
    @include fluid('padding-top', 150px, 150px);
    @include grid-layout(3, 18px);
}

.login-page__content {
    @include grid-columns(2, 1);
    text-align: center;
}

.login-page__content-logo {
    @include fluid('width', 80px, 200px);
    height: auto;
}

.login-page__content-heading {
    @include fluid('font-size', 36px, 36px);
    font-family: var(--f-family--secondary-light);
}

.login-page__content-form {
    @include fluid('width', 499px, 499px);
    @include fluid('padding-top', 65px, 65px);
    margin: 0 auto;
    text-align: left;

    .atom-input {
        margin-bottom: 29px;
    }
}

.login-page__content-form--link {
    display: block;
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p)!important;
    font-weight: var(--f-fw-primary--black);
}

.login-page__content-form--buttons {
    display: flex;
    margin-top: 63px;
    column-gap: 20px;
    .atom-button {
        justify-content: center;
    }
}
</style>
